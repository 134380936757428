import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface UiState {
	addAccountModalIsOpen: boolean
}

const initialState: UiState = {
	addAccountModalIsOpen: false,
}

const uiSlice = createSlice({
	name: 'ui',
	initialState,
	reducers: {
		setAddAccountModallState: (state, { payload }: PayloadAction<boolean>) => {
			state.addAccountModalIsOpen = payload
		},
	},
})

export const uiActions = { ...uiSlice.actions }
export default uiSlice.reducer
