import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { Table, TableCell, TableHeader, TablePlaceholder } from '../../components/ui'
import { useAppDispatch, useAppSelector, useMediaQuery } from '../../hooks'
import { deleteAccount, getTransactionsByAccount, Transaction } from '../../services'
import { robloxAccountsActions } from '../../store/slices/robloxAccountsSlice'
import AccountHistorySubTable from '../AccountHistorySubTable/AccountHistorySubTable'
import { headers } from './ActiveAccountsTable.constants'
import { ActiveAccountsTableRow } from './ActiveAccountsTableRow'

const ActiveAccountsTable = () => {
	const activeAccounts = useAppSelector((state) => state.robuxAccounts.activeAccounts)
	const [activeHistory, setActiveHistory] = useState<number | null>(null)
	const [transactionHistory, setTransactionHistory] = useState<Transaction[]>([])

	const isMobile = useMediaQuery('(max-width: 700px)')

	useEffect(() => {
		if (activeHistory !== null) {
			getTransactionsByAccount(activeHistory).then((data) => setTransactionHistory(data.data))
		} else {
			setTransactionHistory([])
		}
	}, [activeHistory])

	const dispatch = useAppDispatch()

	const historyHandler = (id: number) => {
		if (id === activeHistory) setActiveHistory(null)
		else setActiveHistory(id)
	}

	const deleteAccountHandler = async (id: number) => {
		try {
			await deleteAccount(id)
			toast('Account deleted', { type: 'success' })
			dispatch(robloxAccountsActions.fetchActiveAccounts())
		} catch (error) {
			if (error instanceof AxiosError) {
				const msg = error.response?.data.message ?? 'Error'
				toast(msg, { type: 'error' })
			}
		}
	}

	const header = (
		<TableHeader>
			{headers.map((header, idx) => (
				<TableCell title='' key={idx}>
					{header}
				</TableCell>
			))}
		</TableHeader>
	)
	const rows = activeAccounts.map((account, idx) => {
		const Row = (
			<ActiveAccountsTableRow
				key={account.id}
				account={account}
				onDelete={() => deleteAccountHandler(account.id)}
				onOpenHistory={() => historyHandler(account.id)}
			/>
		)
		return activeHistory === account.id ? (
			<AccountHistorySubTable key={idx} topRow={Row} data={transactionHistory} />
		) : (
			Row
		)
	})

	return (
		<Table columns={headers.length}>
			{!isMobile && header}
			{rows.length ? rows : <TablePlaceholder />}
		</Table>
	)
}

export default ActiveAccountsTable
