import styled, { css } from 'styled-components'
import Button from './Button'

export interface TabButtonProps {
	active: boolean
}
const TabButton = styled(Button)<TabButtonProps>`
	margin-right: 20px;
	border: 2px solid #5b7efb;
	${(props) => {
		if (props.active) {
			return css`
				background: #5b7efb;
				&:hover {
					background: #5b7efb;
				}
			`
		} else {
			return css`
				color: #5b7efb;
				background: unset;
				&:hover {
					background: unset;
				}
			`
		}
	}}
	@media (max-width: 700px) {
		height: 50px;
		padding: 0 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 10px;
	}

	@media (max-width: 400px) {
		padding: 0 2px;
	}
`

export default TabButton
