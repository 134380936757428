import styled from 'styled-components'
import { Button, Input, TabButton } from '../ui'

export const Form = styled.form`
	max-width: 570px;
	width: 100%;
	background: #ffffff;
	border-radius: 15px;
`
export const FormInput = styled(Input)`
	margin-top: 30px;
`
export const InputFields = styled.div`
	& > * {
		margin-bottom: 30px;
	}
	margin-bottom: 60px;
`
export const Submit = styled(Button)`
	width: 100%;
`

export const TypeButton = styled(TabButton)`
	height: unset;
	padding: 8px 22px;
`
export const TypeButtonsWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 40px;
`
export const ProxyHelper = styled.div`
	margin-bottom: 10px;
`
