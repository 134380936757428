import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import App from './App'
import GlobalStyles from './GlobalStyles'
import store from './store'

const app = (
	<BrowserRouter>
		<React.StrictMode>
			<Provider store={store}>
				<GlobalStyles />
				<App />
			</Provider>
		</React.StrictMode>
	</BrowserRouter>
)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(app)
