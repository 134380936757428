import { FC, ReactNode } from 'react'
import { CardContainer, Content, Title } from './styles'

export interface InfoCardProps {
	title: ReactNode
	content: ReactNode
}

const InfoCard: FC<InfoCardProps> = ({ content, title }) => {
	return (
		<CardContainer>
			<Title>{title}</Title>
			<Content>{content}</Content>
		</CardContainer>
	)
}

export default InfoCard
