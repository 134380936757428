import { useEffect, useState } from 'react'

import { StatisticInfo, getSystemInfo } from '../../services'
import CardsWithInfo from '../CardsWithInfo/CardsWithInfo'
import PricesList from './PricesList/PricesList'

const Statistic = () => {
	const [statistic, setStatistic] = useState<StatisticInfo | null>(null)

	useEffect(() => {
		getSystemInfo().then((res) => {
			setStatistic(res.data)
		})
	}, [])

	const statisTicData = [
		{
			title: 'Total',
			content: `${statistic?.blance || 0} R$`,
		},
		{
			title: 'Pre-orders',
			content: `${statistic?.robuxReserved || 0} R$`,
		},
		{
			title: (
				<>
					Orders in queue <PricesList prices={statistic?.orderPrices ?? []} />
				</>
			),
			content: `${statistic?.ordersCount || 0}`,
		},
	]

	return <CardsWithInfo title='STATISTICS' cardData={statisTicData} />
}

export default Statistic
