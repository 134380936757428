import styled from 'styled-components'

export const CardContainer = styled.div`
	background: #ffffff;
	border-radius: 15px;
	width: 370px;
	height: 140px;
	padding: 30px 40px;
	box-shadow: 0px 4px 13px rgba(255, 255, 255, 0.35);
	@media (max-width: 700px) {
		width: 95%;
		height: 74px;
		padding: 0 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		box-shadow: 0px 4px 13px rgba(255, 255, 255, 0.15);
	}
`
export const Content = styled.div`
	font-weight: 700;
	font-size: 30px;
	color: #5b7efb;
	@media (max-width: 700px) {
		font-size: 24px;
	}
`
export const Title = styled.h3`
	font-weight: 400;
	font-size: 20px;
	margin: 0 0 14px 0;
	@media (max-width: 700px) {
		font-size: 17px;
		margin: 0;
	}
`
