import { FC, PropsWithChildren } from 'react'

import { ActionBtns } from '../index'
import { Container } from '../ui'
import { Content, HeaderWrapper, MainWrapper } from './styles'

const Main: FC<PropsWithChildren<unknown>> = ({ children }) => {
	return (
		<MainWrapper>
			<Container>
				<Content>
					<HeaderWrapper>
						<ActionBtns />
					</HeaderWrapper>

					{children}
				</Content>
			</Container>
		</MainWrapper>
	)
}

export default Main
