import { FC } from 'react'

import { RobloxAccountWithPostition } from '../../services'
import { TableRow, TableCell } from '../ui'
import { formatDate } from '../../helpers'
import historyIcon from '../../assets/img/history.svg'
import removeIcon from '../../assets/img/remove.svg'
import { headers } from './ActiveAccountsTable.constants'

export interface ActiveAccountsTableRowProps {
	account: RobloxAccountWithPostition
	onDelete: () => void
	onOpenHistory: () => void
}

export const ActiveAccountsTableRow: FC<ActiveAccountsTableRowProps> = ({
	account,
	onDelete,
	onOpenHistory,
}) => {
	return (
		<TableRow>
			<TableCell title={headers[0]}>{account.id}</TableCell>
			<TableCell title={headers[1]}>{account.balance} R$</TableCell>
			<TableCell title={headers[2]}>{formatDate(account.createdAt)}</TableCell>
			<TableCell title={headers[3]}>{account.position}</TableCell>
			<TableCell title=''>
				<img style={{ cursor: 'pointer' }} onClick={onDelete} src={removeIcon} />
			</TableCell>
			<TableCell title=''>
				<img style={{ cursor: 'pointer' }} onClick={onOpenHistory} src={historyIcon} />
			</TableCell>
		</TableRow>
	)
}
