import { api } from './config'

export enum RobloxAccountAuthType {
	Cookie = 'Cookie',
	Pass = 'Pass',
}

export interface RobloxAccount {
	id: number
	balance: number
	accountId: string
	accountName: string
	createdAt: Date
	isActive: boolean
	authType: RobloxAccountAuthType
	cookie: string
	login: string
	password: string
	userId: number
}
export type RobloxAccountWithPostition = RobloxAccount & { position: number }
export type NewAccountData = Partial<
	Pick<RobloxAccount, 'authType' | 'login' | 'password' | 'cookie'>
> & { proxy?: string }

export const getActiveAccounts = () =>
	api.get<RobloxAccountWithPostition[]>('roblox-account/active')

export const getUnactiveAccounts = () => api.get<RobloxAccount[]>('roblox-account/unactive')

export const addNewAccount = (data: NewAccountData) => api.post('roblox-account', data)

export const deleteAccount = (id: number) => api.get(`roblox-account/disable/${id}`)
