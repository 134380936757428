/* eslint-disable @typescript-eslint/no-unused-vars */
import { FormEvent, useState } from 'react'
import { toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { addNewAccount, RobloxAccountAuthType } from '../../services/robloxAccounts'
import { robloxAccountsActions } from '../../store/slices/robloxAccountsSlice'
import { uiActions } from '../../store/slices/uiSlice'
import Modal from '../Modal/Modal'

import { Header, Input } from '../ui'
import { Form, InputFields, ProxyHelper, Submit, TypeButton, TypeButtonsWrapper } from './styles'

const AddAccountModal = () => {
	const isOpen = useAppSelector((state) => state.ui.addAccountModalIsOpen)
	const [login, setLogin] = useState<string>('')
	const [password, setPassword] = useState<string>('')
	const [cookie, setCookie] = useState<string>('')
	const [proxy, setProxy] = useState('')

	const dispatch = useAppDispatch()

	const closeHandler = () => {
		dispatch(uiActions.setAddAccountModallState(false))
		setLogin('')
		setPassword('')
		setCookie('')
	}

	const submitHandler = async (e: FormEvent<HTMLButtonElement>) => {
		e.preventDefault()
		const addPromise = addNewAccount({
			authType: RobloxAccountAuthType.Cookie,
			cookie,
			login,
			password,
			proxy,
		})
		toast.promise(addPromise, {
			pending: 'Checking your account',
			success: {
				render: () => {
					closeHandler()
					dispatch(robloxAccountsActions.fetchActiveAccounts())
					return 'Account added'
				},
			},
			error: {
				render: (e) => e.data.response.data.message || 'Error',
			},
		})
	}

	if (!isOpen) {
		return <></>
	}

	return (
		<Modal close={closeHandler}>
			<Header>Add an account</Header>
			<Form>
				<InputFields>
					<Input
						key='cookie'
						id='cookie'
						label='Cookie'
						value={cookie}
						onChange={(e) => setCookie(e.target.value)}
					/>
				</InputFields>
				<ProxyHelper>You can add format proxy: login:pass@1.1.1.1:333</ProxyHelper>
				<InputFields>
					<Input
						key='proxy'
						id='proxy'
						label='Proxy'
						value={proxy}
						onChange={(e) => setProxy(e.target.value)}
					/>
				</InputFields>
				<Submit onClick={submitHandler}>Add</Submit>
			</Form>
		</Modal>
	)
}

export default AddAccountModal
