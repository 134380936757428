import { configureStore } from '@reduxjs/toolkit'
import robloxAccountsSlice from './slices/robloxAccountsSlice'
import uiSlice from './slices/uiSlice'
import userSlice from './slices/userSlice'

const store = configureStore({
	reducer: {
		user: userSlice,
		ui: uiSlice,
		robuxAccounts: robloxAccountsSlice,
	},
	devTools: process.env.NODE_ENV !== 'production',
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
