import { useEffect } from 'react'

import { UnactiveAccountsTable } from '../../components'
import { useAppDispatch } from '../../hooks'
import { robloxAccountsActions } from '../../store/slices/robloxAccountsSlice'

const UnactiveAccounts = () => {
	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(robloxAccountsActions.fetchUnactiveAccounts())
	}, [])

	return <UnactiveAccountsTable />
}

export default UnactiveAccounts
