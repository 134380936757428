import { createContext, FC, PropsWithChildren, useContext } from 'react'
import styled from 'styled-components'
import { useMediaQuery } from '../../hooks'

const TableWrapper = styled.div`
	width: 100%;
	display: grid;
	gap: 5px;
`
const Tr = styled.div<{ columns: number }>`
	border: 2px solid #f2f5ff;
	border-radius: 100px;
	height: 60px;
	font-size: 17px;
	line-height: 17px;
	display: grid;
	padding: 0 3em;
	grid-template-rows: 1fr;
	grid-template-columns: ${(props) => Array(props.columns).fill('1fr').join(' ')};

	@media (max-width: 700px) {
		display: flex;
		flex-flow: column;
		gap: 20px;
		height: fit-content;
		border-radius: 15px;
		margin: 0 10px;
		padding: 20px;
	}
`
const Th = styled(Tr)`
	background: #23272a;
	font-weight: 500;
	color: #fff;

	@media (max-width: 700px) {
		height: fit-content;
	}
`
export const TableCellSpan = styled.span<{ isGreen?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: ${(props) => (props?.isGreen ? '#00AF07' : 'inherit')};

	@media (max-width: 700px) {
		display: flex;
		justify-content: space-between;
	}
`

export const TableCellTitle = styled.span`
	font-weight: 500;
`

const tableContext = createContext(0)

export const TableHeader: FC<PropsWithChildren<unknown>> = ({ children }) => {
	const columns = useContext(tableContext)
	return <Th columns={columns}>{children}</Th>
}
export const TableRow: FC<PropsWithChildren<unknown>> = ({ children }) => {
	const columns = useContext(tableContext)
	return <Tr columns={columns}>{children}</Tr>
}

export interface TableProps {
	columns: number
}

export const Table: FC<PropsWithChildren<TableProps>> = ({ children, columns }) => {
	return (
		<TableWrapper>
			<tableContext.Provider value={columns}>{children}</tableContext.Provider>
		</TableWrapper>
	)
}

export const TableCell: FC<PropsWithChildren<{ isGreen?: boolean; title: string }>> = ({
	title,
	children,
	isGreen,
}) => {
	const isMobile = useMediaQuery('(max-width: 700px)')

	return (
		<TableCellSpan isGreen={isGreen}>
			{isMobile && <TableCellTitle>{title}</TableCellTitle>}
			<span>{children}</span>
		</TableCellSpan>
	)
}
