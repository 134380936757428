import { api } from './config'

export interface Transaction {
	id: number
	createdAt: string
	rate: number
	earned: number
	order: {
		robuxWithCommission: number
		id: number
	}
}

export const getTransactionsByAccount = (accountId: number) =>
	api.get<Transaction[]>(`/order/transactions/account/${accountId}`)
