import { FC, PropsWithChildren } from 'react'
import { ModalContent, ModalWrapper } from './styles'

export interface ModalProps {
	close: () => void
}

const Modal: FC<PropsWithChildren<ModalProps>> = ({ children, close }) => {
	return (
		<ModalWrapper onClick={close}>
			<ModalContent onClick={(e) => e.stopPropagation()}>{children}</ModalContent>
		</ModalWrapper>
	)
}

export default Modal
