import styled from 'styled-components'
import { Button } from '../ui'

export const BtnsWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	@media (max-width: 700px) {
		margin: 0 10px;
		width: auto;
	}
`

export const AddButton = styled(Button)`
	@media (max-width: 700px) {
		padding: 0;
		height: 50px;
		width: 50px;
		border-radius: 100%;
	}
`

export const LinksWrapper = styled.div`
	display: flex;
	flex-flow: row;
`
