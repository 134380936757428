import { useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

import { ROUTES_PATH } from '../../App'
import { useMediaQuery } from '../../hooks'
import { uiActions } from '../../store/slices/uiSlice'
import { TabButton } from '../ui'
import { AddButton, BtnsWrapper, LinksWrapper } from './styles'

const ActionBtns = () => {
	const { pathname } = useLocation()
	const dispatch = useDispatch()

	const isMobile = useMediaQuery('(max-width: 700px)')

	return (
		<>
			<BtnsWrapper>
				<LinksWrapper>
					<Link to={ROUTES_PATH.ACTIVE_ACCOUNTS} style={{ textDecoration: 'none' }}>
						<TabButton active={pathname.includes(ROUTES_PATH.ACTIVE_ACCOUNTS)}>
							Active Accounts
						</TabButton>
					</Link>
					<Link to={ROUTES_PATH.UNACTIVE_ACCOUNTS} style={{ textDecoration: 'none' }}>
						<TabButton active={pathname.includes(ROUTES_PATH.UNACTIVE_ACCOUNTS)}>
							Inactive accounts
						</TabButton>
					</Link>
				</LinksWrapper>

				<AddButton onClick={() => dispatch(uiActions.setAddAccountModallState(true))}>
					{isMobile ? '+' : 'Add'}
				</AddButton>
			</BtnsWrapper>
		</>
	)
}

export default ActionBtns
