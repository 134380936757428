import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
	RobloxAccount,
	getActiveAccounts,
	RobloxAccountWithPostition,
	getUnactiveAccounts,
} from '../../services'

interface RobloxAccountsState {
	activeAccounts: RobloxAccountWithPostition[]
	unactive: RobloxAccount[]
}

const fetchActiveAccounts = createAsyncThunk('robloxAccounts/fetchActive', async () => {
	const res = await getActiveAccounts()
	return res.data
})

const fetchUnactiveAccounts = createAsyncThunk('robloxAccounts/fetchUnactive', async () => {
	const res = await getUnactiveAccounts()
	return res.data
})

const initialRobloxAccountsState: RobloxAccountsState = {
	activeAccounts: [],
	unactive: [],
}

const robloxAccountsSlice = createSlice({
	name: 'robloxAccounts',
	initialState: initialRobloxAccountsState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchActiveAccounts.fulfilled, (state, { payload }) => {
			state.activeAccounts = payload
		})
		builder.addCase(fetchUnactiveAccounts.fulfilled, (state, { payload }) => {
			state.unactive = payload
		})
	},
})

export const robloxAccountsActions = {
	...robloxAccountsSlice.actions,
	fetchActiveAccounts,
	fetchUnactiveAccounts,
}
export default robloxAccountsSlice.reducer
