import styled from 'styled-components'

const Header = styled.h1`
	font-weight: 700;
	font-size: 36px;
	line-height: 145%;
	text-align: center;
`

export default Header
