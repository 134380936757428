import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

const PageWrappert = styled.div`
	background-color: #23272a;
	min-height: 100vh;
`

const SigninLayout = () => {
	return (
		<PageWrappert>
			<Outlet />
		</PageWrappert>
	)
}

export default SigninLayout
