import styled from 'styled-components'
import { useOpen } from '../../../hooks'
import { FC, useEffect } from 'react'

const Parent = styled.span`
	position: relative;
`

const Clickable = styled.span`
	cursor: pointer;
`

const ListWrapper = styled.div`
	position: absolute;
	border: 2px solid #5b7efb;
	border-radius: 10px;
	background: white;
	max-height: 250px;
	width: 160px;
	padding: 10px 0 10px 15px;
	left: 0;
`

const List = styled.div`
	max-height: 230px;
	overflow-y: auto;
`

export interface PricesListProps {
	prices: { robuxWithCommission: number; gamePassExist: boolean }[]
}

const PricesList: FC<PricesListProps> = ({ prices }) => {
	const { isOpen, close, open } = useOpen()

	useEffect(() => {
		document.body.addEventListener('click', close)

		return () => {
			document.body.removeEventListener('click', close)
		}
	}, [])

	prices.sort((a, b) => a.robuxWithCommission - b.robuxWithCommission)

	return (
		<Parent>
			<Clickable
				onClick={(e) => {
					e.stopPropagation()
					open()
				}}
			>
				❓{' '}
			</Clickable>
			{isOpen && (
				<ListWrapper>
					<List>
						{prices.map(({ gamePassExist, robuxWithCommission }, idx) => (
							<div key={idx}>
								{robuxWithCommission} R$ {!gamePassExist && '❗️'}{' '}
							</div>
						))}
					</List>
				</ListWrapper>
			)}
		</Parent>
	)
}

export default PricesList
