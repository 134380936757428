import styled from 'styled-components'

export const SubTableWrapper = styled.div`
	border: 2px solid #5b7efb;
	border-radius: 15px;
`
export const TopRow = styled.div`
	border-bottom: 2px solid #5b7efb;

	& > * {
		border: none;
		height: 56px;

		@media (max-width: 700px) {
			height: fit-content;
		}
	}
`
export const MainContent = styled.div`
	padding: 30px;

	@media (max-width: 700px) {
		background: #f2f5ff;
	}
`
export const Title = styled.div`
	font-weight: 700;
	font-size: 28px;
	margin-left: 45px;
	margin-bottom: 20px;
`
