import { MouseEvent, useState } from 'react'

import { Input } from '../../components/ui'
import { useAppDispatch } from '../../hooks'
import { userActions } from '../../store/slices/userSlice'
import { Wrapper, SigninForm, FormHeader, FieldsWrapper, Btn } from './styles'

const SigninPage = () => {
	const [key, setKey] = useState<string>('')

	const dispatch = useAppDispatch()

	const clickHandler = (e: MouseEvent) => {
		e.preventDefault()
		dispatch(userActions.signin(key))
	}

	return (
		<Wrapper>
			<SigninForm>
				<FormHeader>Login</FormHeader>
				<FieldsWrapper>
					<Input id='key' onChange={(e) => setKey(e.target.value)} label='Key' />
				</FieldsWrapper>
				<Btn onClick={clickHandler}>Sign in</Btn>
			</SigninForm>
		</Wrapper>
	)
}

export default SigninPage
