import styled from 'styled-components'

const Button = styled.button`
	cursor: pointer;
	border: none;
	padding: 21px 40px;
	gap: 10px;
	height: 60px;
	background: #00a9ce;
	border-radius: 100px;
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	line-height: 17px;
	color: #ffffff;
	&:hover {
		background: #018faf;
	}
`

export default Button
