import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		margin: 0;
	}

	*, *::after, *::before {
		box-sizing: border-box;
	}
`

export default GlobalStyles
