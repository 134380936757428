import { api } from './config'

export enum Roles {
	Admin = 'Admin',
	SuperAdmin = 'SuperAdmin',
	Partner = 'Partner',
}

export enum Currency {
	RUB = 'RUB',
	EUR = 'EUR',
	USD = 'USD',
}

export interface User {
	balance: number
	balanceRobux: number
	id: number
	link: string
	name: string
	priority: number
	rate: number
	type: Roles.Partner
	currency: Currency
}

export const getPartnerData = () => api.get<User>('/users/info')
