import { useEffect } from 'react'
import { ActiveAccountsTable } from '../../components'
import { useAppDispatch } from '../../hooks'
import { robloxAccountsActions } from '../../store/slices/robloxAccountsSlice'

const ActiveAccounts = () => {
	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(robloxAccountsActions.fetchActiveAccounts())
	}, [])

	return <ActiveAccountsTable />
}

export default ActiveAccounts
