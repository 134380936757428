import { FC } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import { MainLayout, SigninLayout } from './components'
import { useAppSelector } from './hooks'
import { Active, Signin, Unactive } from './pages'

export const ROUTES_PATH = {
	SIGNIN: 'signin',
	ACTIVE_ACCOUNTS: 'accounts/active',
	UNACTIVE_ACCOUNTS: 'accounts/unactive',
	NOT_FOUND: '*',
}

const App: FC = () => {
	const isAuth = useAppSelector((state) => !!state.user.token)

	if (!isAuth) {
		return (
			<Routes>
				<Route element={<SigninLayout />}>
					<Route path={ROUTES_PATH.SIGNIN} element={<Signin />} />
				</Route>
				<Route
					path={ROUTES_PATH.NOT_FOUND}
					element={<Navigate to={ROUTES_PATH.SIGNIN} />}
				/>
			</Routes>
		)
	}

	return (
		<Routes>
			<Route element={<MainLayout />}>
				<Route path={ROUTES_PATH.ACTIVE_ACCOUNTS} element={<Active />} />
				<Route path={ROUTES_PATH.UNACTIVE_ACCOUNTS} element={<Unactive />} />
			</Route>

			<Route
				path={ROUTES_PATH.NOT_FOUND}
				element={<Navigate to={ROUTES_PATH.ACTIVE_ACCOUNTS} />}
			/>
		</Routes>
	)
}

export default App
