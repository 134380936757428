import { FC, ReactNode } from 'react'

import { formatDate } from '../../helpers'
import { useMediaQuery } from '../../hooks'
import { Transaction } from '../../services'
import { SubTable } from '../index'
import { Table, TableCell, TableHeader, TablePlaceholder, TableRow } from '../ui'

const historyHeaders = ['Number of R$', 'Payment date', 'Rate', 'Money earned']

export interface AccountHistorySubTableProps {
	topRow: ReactNode
	data: Transaction[]
}

const AccountHistorySubTable: FC<AccountHistorySubTableProps> = ({ topRow, data }) => {
	const isMobile = useMediaQuery('(max-width: 700px)')

	const table = (
		<Table columns={historyHeaders.length}>
			{!isMobile && (
				<TableHeader>
					{historyHeaders.map((header, idx) => (
						<TableCell key={idx} title=''>
							{header}
						</TableCell>
					))}
				</TableHeader>
			)}
			{data.map((history) => (
				<TableRow key={history.id}>
					<TableCell title={historyHeaders[0]}>
						{history.order.robuxWithCommission}
					</TableCell>
					<TableCell title={historyHeaders[1]}>{formatDate(history.createdAt)}</TableCell>
					<TableCell title={historyHeaders[2]}>1кR$ = {history.rate}₽</TableCell>
					<TableCell title={historyHeaders[3]} isGreen>
						{history.earned}₽
					</TableCell>
				</TableRow>
			))}
			{!data.length && <TablePlaceholder />}
		</Table>
	)

	const totalSpent = data.reduce(
		(acc, { order: { robuxWithCommission } }) => acc + robuxWithCommission,
		0
	)

	return (
		<SubTable
			topRow={topRow}
			title={`Transaction history (total: ${totalSpent} R$)`}
			table={table}
		/>
	)
}

export default AccountHistorySubTable
