import { useEffect, useState } from 'react'

import { Table, TableCell, TableHeader, TableRow } from '../ui'
import AccountHistorySubTable from '../AccountHistorySubTable/AccountHistorySubTable'

import historyIcon from '../../assets/img/history.svg'
import { useAppSelector, useMediaQuery } from '../../hooks'
import { formatDate } from '../../helpers'
import { getTransactionsByAccount, Transaction } from '../../services'

const headers = ['ID', 'Date added', 'History']

const UnactiveAccountsTable = () => {
	const unactiveAccounts = useAppSelector((state) => state.robuxAccounts.unactive)
	const [activeHistory, setActiveHistory] = useState<number | null>(null)
	const [transactionHistory, setTransactionHistory] = useState<Transaction[]>([])

	const isMobile = useMediaQuery('(max-width: 700px)')

	useEffect(() => {
		if (activeHistory !== null) {
			getTransactionsByAccount(activeHistory).then((data) => setTransactionHistory(data.data))
		} else {
			setTransactionHistory([])
		}
	}, [activeHistory])

	const historyHandler = (id: number) => {
		if (id === activeHistory) setActiveHistory(null)
		else setActiveHistory(id)
	}

	const rows = unactiveAccounts.map((account) => {
		const Row = (
			<TableRow key={account.id}>
				<TableCell title={headers[0]}>{account.id}</TableCell>
				<TableCell title={headers[1]}>{formatDate(account.createdAt)}</TableCell>
				<TableCell title={headers[2]}>
					<img
						style={{ cursor: 'pointer' }}
						onClick={() => historyHandler(account.id)}
						src={historyIcon}
					/>
				</TableCell>
			</TableRow>
		)

		return activeHistory === account.id ? (
			<AccountHistorySubTable key={account.id} topRow={Row} data={transactionHistory} />
		) : (
			Row
		)
	})

	return (
		<Table columns={headers.length}>
			{!isMobile && (
				<TableHeader>
					{headers.map((header) => (
						<TableCell title='' key={header}>
							{header}
						</TableCell>
					))}
				</TableHeader>
			)}
			{rows}
		</Table>
	)
}

export default UnactiveAccountsTable
