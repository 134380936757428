import styled from 'styled-components'

export const ModalWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #23272a85;
`

export const ModalContent = styled.div`
	padding: 50px 100px;
	max-width: 570px;
	width: 100%;
	height: fit-content;
	background: #ffffff;
	border-radius: 15px;
`
