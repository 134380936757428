import axios from 'axios'
import { getAuthFromStorage } from '../helpers'

const isProd = process.env.NODE_ENV === 'production'
const baseURL = !isProd ? 'https://24devapi.ru:3333/' : 'https://apirbx.ru/'
console.log(baseURL)

export const api = axios.create({
	baseURL: baseURL,
	timeout: 20000,
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
	},
})

api.interceptors.request.use((req) => {
	const { access_token } = getAuthFromStorage()
	if (access_token) {
		req.headers = {
			...req.headers,
			Authorization: `Bearer ${access_token}`,
		}
	}
	return req
})
