import { api } from './config'

export interface StatisticInfo {
	blance: number
	ordersCount: number
	robuxReserved: number
	orderPrices: { robuxWithCommission: number; gamePassExist: boolean }[]
}

export const getSystemInfo = () => api.get<StatisticInfo>('/system/info?extended=true')
