import styled from 'styled-components'

export const InfoContainer = styled.div`
	padding-top: 70px;
	@media (max-width: 700px) {
		padding-top: 30px;
	}
`
export const Title = styled.h2`
	font-weight: 700;
	font-size: 36px;
	margin: 0 0 40px 50px;
	color: #fff;
	@media (max-width: 700px) {
		font-size: 28px;
		margin: 0 0 20px 15px;
	}
`
export const CardWrapper = styled.div`
	display: flex;
	gap: 30px;

	@media (max-width: 700px) {
		flex-flow: column;
		align-items: center;
		gap: 10px;
	}
`
