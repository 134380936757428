import { FC, ReactNode } from 'react'
import { MainContent, SubTableWrapper, Title, TopRow } from './styles'

export interface SubTable {
	topRow: ReactNode
	title: string
	table: ReactNode
}

const SubTable: FC<SubTable> = ({ topRow, title, table }) => {
	return (
		<SubTableWrapper>
			<TopRow>{topRow}</TopRow>
			<MainContent>
				<Title>{title}</Title>
				{table}
			</MainContent>
		</SubTableWrapper>
	)
}

export default SubTable
