import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { useAppDispatch } from '../../hooks'
import { userActions } from '../../store/slices/userSlice'
import { AddAccountModal, Info, Main, Statistic } from '..'

const PageWrapper = styled.div`
	background-color: #23272a;
	min-height: 100vh;
`

const Wrapper = styled.main`
	margin: 0 auto;
	max-width: 1520px;
`

const MainLayout = () => {
	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(userActions.getDataUserData())
	}, [])

	return (
		<PageWrapper>
			<Wrapper>
				<Info />
				<Statistic />
				<Main>
					<Outlet />
				</Main>
			</Wrapper>

			<AddAccountModal />
			<ToastContainer />
		</PageWrapper>
	)
}

export default MainLayout
