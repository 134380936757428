/* eslint-disable indent */
import { useAppSelector } from '../../hooks'
import { Currency } from '../../services'
import CardsWithInfo from '../CardsWithInfo/CardsWithInfo'

const Info = () => {
	const user = useAppSelector((state) => state.user)

	const currency =
		user.currency === Currency.RUB
			? '₽'
			: user.currency === Currency.USD
			? '$'
			: user.currency === Currency.EUR
			? '€'
			: '₽' || '₽'

	const cardData = [
		{
			title: 'My robux',
			content: `${user.balanceRobux || 0} R$`,
		},
		{
			title: 'Money',
			content: `${(user.balance ?? 0).toFixed(2)}${currency}`,
		},
		{
			title: 'Rate',
			content: `1кR$ = ${user.rate || 1}${currency}`,
		},
	]

	return <CardsWithInfo title='PERSONAL INFORMATION' cardData={cardData} />
}

export default Info
