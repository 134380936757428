import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAuthFromStorage, removeAuthFromStorage, setAuthInStorage } from '../../helpers'
import { getPartnerData, signinPartner, User } from '../../services'

type UserState = Partial<User> & { token?: string }

const signin = createAsyncThunk('user/signin', async (key: string) => {
	const { access_token } = (await signinPartner(key)).data
	return access_token
})

const getDataUserData = createAsyncThunk('user/getData', async () => {
	const adminData = (await getPartnerData()).data
	return adminData
})

const initialState: UserState = {
	token: getAuthFromStorage().access_token,
}

const userSlice = createSlice({
	name: 'user',
	initialState: initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(signin.fulfilled, (state, { payload }) => {
			state.token = payload
			setAuthInStorage({ access_token: payload })
		})
		builder.addCase(getDataUserData.fulfilled, (state, { payload }) => {
			state.name = payload.name
			state.id = payload.id
			state.type = payload.type
			state.balance = payload.balance
			state.balanceRobux = payload.balanceRobux
			state.priority = payload.priority
			state.rate = payload.rate
			state.link = payload.link
			state.currency = payload.currency
		})

		builder.addCase(getDataUserData.rejected, (state, { error: { message } }) => {
			if (message?.includes('401')) {
				removeAuthFromStorage()
				return initialState
			}
		})
	},
})

export const userActions = { ...userSlice.actions, signin, getDataUserData }
export default userSlice.reducer
